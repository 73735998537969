import { useHistory } from 'react-router-dom';
import { Storage } from '@capacitor/storage';

import { IonPopover, IonButton, IonAlert, useIonAlert, IonText, IonInput, IonLabel, IonItem, IonAccordionGroup, IonAccordion, IonSpinner } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import API_URL from '../utils/api';

interface data {
  date: string;
  time: string;
  location: string;
}

const AccountComponent: React.FC = () => {
  //const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [token, setToken] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const history = useHistory();
  const [ionAlert] = useIonAlert();
  var [deleteSuccessAlert] = useIonAlert();
  const [voornaam, setVoornaam] = useState<string>('');
  const [familienaam, setFamilienaam] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [emailadres, setEmailadres] = useState<string>('');
  const [wachtwoord, setWachtwoord] = useState<string>('');
  const [cwachtwoord, setCWachtwoord] = useState<string>('');
  const [telefoon, setTelefoon] = useState<number>(0);
  const [adress, setAdress] = useState<string>('');
  const [postcode, setPostcode] = useState<number>(0);
  const [plaatsnaam, setPlaatsnaam] = useState<string>('');
  const [land, setLand] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [spinner, setSpinner] = useState<string>('c-spinner-hide');
  const [present] = useIonAlert();





  useEffect(() => {
    checkAuthentication();


  }, []);



  const checkAuthentication = async () => {
    const tokenValidTo = await Storage.get({ key: `token_valid_to` });
    if (tokenValidTo.value && parseInt(tokenValidTo.value) > new Date().getTime()) {
      const { value } = await Storage.get({ key: 'token' });
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + value);
      //myHeaders.append('Content-Type', 'application/json');
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      setSpinner('c-spinner');

      await fetch(`${API_URL}/account`, requestOptions)
        .then(async (response) => {
          if (!response.ok) {
            throw await response.text();
          }
          setError('');
          //setInfo(await response.text());
          setSpinner('c-spinner-hide');
          response.text().then(dtoAccount => {
            var account = JSON.parse(dtoAccount);
            if(account.firstname) setVoornaam(account.firstname);
            if(account.lastname) setFamilienaam(account.lastname);
            if(account.username) setUsername(account.username);
            if(account.email) setEmailadres(account.email);
            if(account.phone) {
              setTelefoon(account.phone);
            }
            
            if(account.address) setAdress(account.address);
            if(account.postalCode) {
              setPostcode(account.postalCode);
            }
            
            // if(account.city) setPlaatsnaam(account.city);
            // if(account.country) setLand(account.country);

          })
        })
        .catch((error) => {
          if (error.message) {
            setError(error.message);
          }
          else {
            setError(error);
          }
          setSpinner('c-spinner-hide');
        });
    }
    else {
      const location = {
        pathname: '/login',
        state: { fromDashboard: true },
      };
      const removeLocalstorage = async () => {
        await Storage.remove({ key: 'token' });
        await Storage.remove({ key: 'admin' });
        await Storage.remove({ key: 'code' });
        await Storage.remove({ key: 'token_valid_to' });
      };
      removeLocalstorage().then(response => {
        history.push(location);
      });
    }
  };

  const VerwijderAccountVraag = async () => {

    const { value } = await Storage.get({ key: 'token' });
    // @ts-ignore
    setToken(value);

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + value);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
    };


    fetch(`${API_URL}/account?password=${password}`, requestOptions)
      .then((response) => {

        if (response.ok) {
          response.text()
            .then((result) => {
              VerwijderAccount(result);

            });

        }
        else {
          response.text()
            .then((result) => {
              ionAlert({
                cssClass: 'alert-css',
                header: 'Fout',
                message: result,
                buttons: [
                  'ok'
                ]
              });

            })
        }
      }
      )
  };

  const VerwijderAccount = (message: String) => {



    deleteSuccessAlert({
      cssClass: 'alert-css',
      header: 'Verwijderen gelukt',
      message: message.toString(),
      buttons: [
        'ok'
      ]
    });


    const location = {
      pathname: '/login',
      state: { fromDashboard: true },
    };
    const removeLocalstorage = async () => {
      await Storage.remove({ key: 'token' });
      await Storage.remove({ key: 'admin' });
      await Storage.remove({ key: 'code' });
      await Storage.remove({ key: 'token_valid_to' });
    };
    removeLocalstorage().then(response => {
      history.push(location);
    });

  }

  const handleSubmit = async () => {

    if (wachtwoord != cwachtwoord) {
      setError("Beide wachtwoorden moeten overeenkomen");
      return;
    }

    setSpinner('c-spinner');
    
    const { value } = await Storage.get({ key: 'token' });
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + value
    
    },
      body: JSON.stringify({
        firstname: voornaam,
        lastname: familienaam,
        username: username,
        email: emailadres,
        password: wachtwoord,
        confirmpassword: cwachtwoord,
        phone: telefoon,
        address: adress,
        postalCode: postcode,
        city: plaatsnaam,
        country: land,
      }),
    };
    await fetch(`${API_URL}/account`, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw await response.text();
        }
        setError('');
        // setInfo(await response.text());
        // document.getElementById('register-form-content').style.display = 'none';
        // document.getElementById('register-input-success').style.display = 'flex';
        setSpinner('c-spinner-hide');
        present({
          cssClass: 'alert-css',
          header: 'Profiel update',
          message: 'We hebben uw profielgegevens met succes bijgewerkt',
          backdropDismiss: false,
          buttons: [
            'ok'
          ]
          
        });
        //history.push('/Login');
      })
      .catch((error) => {
        if (error.message) {
          setError(error.message);
        }
        else {
          setError(error);
        }
        setSpinner('c-spinner-hide');
      });
  };

  const validatePostcode = (val: number, max: number) => {
    if (val < 0) val = 0;
    if (val > max) val = Math.floor(val / 10);
    setPostcode(val);
  };

  const validateTelefoon = (val: number, max: number) => {
    if (val < 0) val = 0;
    if (val > max) val = Math.floor(val / 10);
    setTelefoon(val);
  };



  return (
    <div id='profile-container' className="container container-logboek container-loggedIn">
      {/* <IonAccordionGroup ref={accordionGroup} multiple={true} value={"first"}> */}
      

      <IonAccordionGroup>

      <IonAccordion toggleIconSlot="start">
      <IonItem slot="header" color="light">
            <IonLabel>

              <div>
              Bewerk profiel
              </div>

            </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <div className="align-left">
            <p className="c-form-error">{error}</p>
                <div id='register-input-success' className='c-form-item' style={{ display: "none" }}>
                  <p className="c-form-info">{info}</p>
                </div>
                <div id='register-form-content'>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label required">
                      Voornaam
                    </label>
                    <input type="text" className="c-form-input" value={voornaam} onChange={(e) => setVoornaam(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label required">
                      Familienaam
                    </label>
                    <input type="text" className="c-form-input" value={familienaam} onChange={(e) => setFamilienaam(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      E-mailadres
                    </label>
                    <input type="email" className="c-form-input" disabled={true} value={emailadres} onChange={(e) => setEmailadres(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Identificatiecode
                    </label>
                    <input type="text" className="c-form-input" disabled={true} value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Wachtwoord
                    </label>
                    <input type="password" className="c-form-input" value={wachtwoord} onChange={(e) => setWachtwoord(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Wachtwoord herhalen
                    </label>
                    <input type="password" className="c-form-input" value={cwachtwoord} onChange={(e) => setCWachtwoord(e.target.value)} />
                  </div>

                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Telefoon
                    </label>
                    <input type="number" min={0} max={9999999999} className="c-form-input" value={telefoon || null} onChange={(e) => validateTelefoon(parseInt(e.target.value), 9999999999)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Straat en huisnummer
                    </label>
                    <input type="text" className="c-form-input" value={adress} onChange={(e) => setAdress(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Postcode
                    </label>
                    <input type="number" min={0} max={9999} className="c-form-input" value={postcode || null} onChange={(e) => validatePostcode(parseInt(e.target.value), 9999)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Plaatsnaam
                    </label>
                    <input type="text" className="c-form-input" value={plaatsnaam} onChange={(e) => setPlaatsnaam(e.target.value)} />
                  </div>
                  <div className="c-form-item">
                    <label htmlFor="" className="c-form-label">
                      Land
                    </label>
                    <input type="text" disabled={false} className="c-form-input" value={land} onChange={(e) => setLand(e.target.value)} />
                  </div>
                  <div className='margin-top-12'>
                    <IonButton onClick={() => handleSubmit()}>Opslaan</IonButton>
                  </div>
                  {/* <button type="submit" className="c-button" onClick={() => handleSubmit()}>
          Registreren
        </button> */}
                </div>
              </div>
              </div>
              
        </IonAccordion>
        <IonAccordion toggleIconSlot="start">
          <IonItem slot="header" color="light">
            <IonLabel>

              <div>
                Verwijder profiel
              </div>

            </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <div className="align-left">
              <p>
                Verwijderen houdt in dat al uw persoonsgegevens in ons systeem verwijderd worden. Alle gegevens over visactiviteiten zullen nog steeds worden bewaard maar zullen geen link meer bevatten met u als persoon. Uw profiel zal na deze actie niet meer gebruikt kunnen worden.
              </p>
              <p>
                Om uw profiel te verwijderen vragen we uw om te bevestigen met uw wachtwoord.
              </p>
              <IonItem>
                <IonInput placeholder='Wachtwoord' type="password" autocomplete='off' value={password} onIonChange={(e) => setPassword(e.detail.value)}></IonInput>
              </IonItem>

              <IonButton className='margin-top-15' color='danger' disabled={password.length < 6} style={{ 'float': 'left' }} onClick={() =>
                ionAlert({
                  header: 'Bent u zeker dat u uw profiel wilt verwijderen?',
                  cssClass: 'custom-alert',
                  buttons: [
                    {
                      text: 'Nee',
                      cssClass: 'alert-button-cancel',
                    },
                    {
                      text: 'Ja',
                      cssClass: 'alert-button-confirm',
                      handler: () => {
                        VerwijderAccountVraag();
                      },
                    },
                  ],
                })
              }>{<span>Verwijder Profiel</span>}</IonButton>
            </div>

          </div>
        </IonAccordion>
      </IonAccordionGroup>
      <div className={spinner}>
          <IonSpinner />
        </div>
    </div>
    
  );
};

export default AccountComponent;
