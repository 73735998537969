export const API_URL: string = 'https://api.recreatievezeevisserij.be/Recvis';
//export const API_URL: string = 'https://dev-api.recreatievezeevisserij.be/Recvis';
//export const API_URL: string = 'https://localhost:44341/recvis';

// export const get = (endpoint: string) => {
//   return fetch(`${BACKEND_URL}/${endpoint}`)
//     .then((r) => r.json())
//     .catch((err) => console.error(err));
// };
export default API_URL;
